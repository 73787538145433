<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend class="d-flex">
              <feather-icon
                icon="SearchIcon"
                class="text-muted align-self-center mx-1"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Search test cases"
              debounce="750"
              @update="updateRouteQuery"
              v-on:keyup.enter="searchButton"
            />
            <b-input-group-append>
              <b-button
                @click="searchButton"
                variant="flat-primary"
                class="px-1"
                pill
              >
                Search
              </b-button>
              <b-button
                @click="refreshTests"
                variant="flat-info"
                class="px-50"
                pill
              >
                <feather-icon icon="RefreshCwIcon" />
              </b-button>
            </b-input-group-append>

            <div class="border-left mx-1" />

            <!-- Pagination -->
            <div class="pagination-container d-flex justify-content-between">
              <b-button
                @click="prevPage"
                :disabled="paginateData.currentPage === 1"
                variant="flat-info"
                class="px-50"
                pill
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="16"
                />
              </b-button>
              <div class="align-self-center mx-1">
                <span
                  v-if="!isEditingPageNumber"
                  @dblclick="editPageNumber"
                >
                  Page {{ paginateData.currentPage }} of {{ totalPages }}
                </span>
                <div
                  v-else
                  class="d-flex flex-row"
                >
                  <span class="align-self-center text-nowrap font-weight-bold">
                    Go to Page:
                  </span>
                  <b-form-input
                    v-model="editablePageNumber"
                    @keyup.enter="finishEditingPageNumber"
                    type="number"
                    size="sm"
                    class="page-number-input text-center pr-0 ml-1"
                  />
                </div>
              </div>
              <b-button
                @click="nextPage"
                :disabled="paginateData.currentPage === totalPages"
                variant="flat-info"
                class="px-50"
                pill
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  size="16"
                />
              </b-button>
              <!--  <b-pagination-->
              <!--    v-model="paginateData.currentPage"-->
              <!--    :per-page="paginateData.perPage"-->
              <!--    :total-rows="totalRows"-->
              <!--    first-number-->
              <!--    last-number-->
              <!--    prev-class="prev-item"-->
              <!--    next-class="next-item"-->
              <!--    class="ml-2 center"-->
              <!--/>-->
            </div>
            <b-form-group
              class="d-flex mb-0 ml-1"
              label-align-sm="left"
              label-cols="6"
            >
              <template #label>
                <span
                  class="font-small-3 my-0"
                >
                  Per Page
                </span>
              </template>
              <b-form-select
                v-model="paginateData.perPage"
                :options="pageOptions"
                @input="postChangePerPageLimit"
                size="sm"
                class="mt-25"
              />
            </b-form-group>
            <!-- ./Pagination -->
          </b-input-group>
        </div>
      </div>
      <!-- Div element that prevents scrolling while loading new data + centers the overlay and spinner -->
      <!-- Couldn't figure out how to prevent perfect scrollbar from scrolling and center a spinner any other way -->
      <div
        v-if="isLoading"
        class="spinner-container"
      >
        <b-overlay
          :show="isLoading"
          variant="transparent"
          :opacity="1"
          rounded="sm"
          no-wrap
        >
          <template #overlay>
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
            />
          </template>
        </b-overlay>
      </div>

      <vue-perfect-scrollbar
        ref="testScrollContainer"
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          ref="testItemGroup"
          v-model="tests"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
          <li
            v-for="test in tests"
            :ref="test.id"
            :key="test.id"
            class="todo-item"
            @click="handleTestClick(test)"
          >
            <feather-icon
              icon="MoreVerticalIcon"
              class="draggable-task-handle d-inline"
            />
            <div class="todo-title-wrapper">

              <div class="todo-title-area">
                <div class="title-wrapper">
                  <span class="todo-title font-weight-bold">{{ test.ref_id }}</span>
                  <span class="todo-title">{{ test.name }}</span>
                </div>
              </div>

              <div class="todo-item-action">
                <!-- Latest Test Result -->
                <div class="badge-wrapper mr-1">
                  <b-badge
                    pill
                    :variant="`light-${resolveLatestTestResult(test).color}`"
                    class="text-capitalize"
                  >
                    {{ test.latest_result || 'untested' }}
                  </b-badge>
                </div>

                <!-- Last Test Run -->
                <!--<small class="text-nowrap text-muted mr-1">{{ formatDate(task.dueDate, { month: 'short', day: 'numeric'}) }}</small>-->

                <!-- Test Priority Icon -->
                <b-avatar
                  size="32"
                  :variant="`light-${resolveTestPriority(test).color}`"
                >
                  <feather-icon
                    :icon="resolveTestPriority(test).icon"
                    size="16"
                  />
                </b-avatar>
              </div>
            </div>

          </li>
        </draggable>
        <div
          class="no-results"
          :class="{'show': !tests.length}"
        >
          <h5>No Items Found</h5>
        </div>
      </vue-perfect-scrollbar>


    </div>

    <!-- Task Handler -->
    <test-case-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :clear-task-data="clearTaskData"
      @closed="hideSidebar"
      @update-test="updateTask"
    >
      <test-case
        v-if="task.ref_id"
        :key="task.ref_id"
        :selected-test-case="task"
        @refresh-tests="refreshTests"
        @test-deleted="() => { hideSidebar(); clearTaskData(); refreshTests(); }"
      />
    </test-case-handler-sidebar>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <test-case-left-sidebar
        :priority-options="testPriorities"
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
        @addTest="fetchTests"
      >
      </test-case-left-sidebar>
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref,
  watch,
  computed,
  onUnmounted,
} from '@vue/composition-api'
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BBadge,
  BAvatar,
  BPagination,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {
  formatDate,
  avatarText,
} from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import TestCaseLeftSidebar from './TestCaseLeftSidebar.vue'
import TestCaseHandlerSidebar from './TestCaseHandlerSidebar.vue'
import TestCase from '../Test/TestCase.vue'

export default {
  components: {
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,
    TestCaseLeftSidebar,
    TestCaseHandlerSidebar,
    TestCase,
  },
  setup() {
    onUnmounted(() => { store.dispatch('tests/clearTests') })

    const { route, router } = useRouter()
    const routeSortBy = computed(() => route.value.query.sort)
    const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    const tests = computed(() => store.state.tests.tests)

    const tasks = ref([])
    const blankCurrentRouteData = { path: '', query: '' }
    const paginateData = ref({ currentPage: 1, totalPages: 1, perPage: 100 })
    const pageOptions = [25, 50, 100, 250, 500]
    const currentRouteData = ref(JSON.parse(JSON.stringify(blankCurrentRouteData)))

    const sortOptions = [
      'latest',
      'title-asc',
      'title-desc',
      'assignee',
      'due-date',
    ]
    const sortBy = ref(routeSortBy.value)
    watch(routeSortBy, val => {
      if (sortOptions.includes(val)) sortBy.value = val
      else sortBy.value = val
    })
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      delete currentRouteQuery.sort

      router.replace({ name: route.name, query: currentRouteQuery }).catch(() => {
      })
    }

    const blankTask = {
      id: null,
      ref_id: '',
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }
    const removeTask = () => {
      fetchTests()
      hideSidebar()
    }
    const updateTask = taskData => {
      fetchTests()
      hideSidebar()
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isTaskHandlerSidebarActive = ref(false)

    const testPriorities = [
      {
        title: 'Critical',
        color: 'danger',
        icon: 'ChevronsUpIcon',
        route: { name: 'model_tests_priority', params: { priority: 'critical' } },
      },
      {
        title: 'High',
        color: 'warning',
        icon: 'ChevronUpIcon',
        route: { name: 'model_tests_priority', params: { priority: 'high' } },
      },
      {
        title: 'Nominal',
        color: 'info',
        icon: 'MinusIcon',
        route: { name: 'model_tests_priority', params: { priority: 'nominal' } },
      },
      {
        title: 'Low',
        color: 'secondary',
        icon: 'ChevronDownIcon',
        route: { name: 'model_tests_priority', params: { priority: 'low' } },
      },
    ]

    const resolveTestPriority = test => {
      if (test.priority.toLowerCase() === 'critical') return testPriorities[0]
      if (test.priority.toLowerCase() === 'high') return testPriorities[1]
      if (test.priority.toLowerCase() === 'nominal') return testPriorities[2]
      if (test.priority.toLowerCase() === 'low') return testPriorities[3]
      return { color: 'dark', icon: 'CircleIcon' }
    }

    const testResults = [
      { result: 'passed', color: 'success', icon: 'CheckIcon' },
      { result: 'failed', color: 'danger', icon: 'SlashIcon' },
      { result: 'untested', color: 'secondary', icon: 'MinusIcon' },
    ]

    const resolveLatestTestResult = test => {
      if (test.latest_result && testResults.find(m => m.result === test.latest_result.toLowerCase())) {
        return testResults.find(m => test.latest_result.toLowerCase() === m.result)
      }
      return testResults.find(m => m.result === 'untested')
    }

    // Search Query
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    // watch([searchQuery, sortBy], () => fetchTests())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q
      currentRouteData.value.query = currentRouteQuery
      currentRouteData.value.path = route.name
    }

    const searchButton = () => {
      paginateData.value.currentPage = 1
      router.replace({ name: currentRouteData.value.path, query: currentRouteData.value.query })
    }

    // Determine focused testcase ID
    const focusedTestId = ref(null)
    watch(
      () => route.value,
      () => {
        if (route.value.query.focus) {
          focusedTestId.value = route.value.query.focus
        } else if (route.value.params.testId) {
          focusedTestId.value = route.value.params.testId
        }
      },
      { immediate: true },
    )

    // Scroll to Node
    const testScrollContainer = ref(null)
    const testItemGroup = ref(null)

    const focusOnTest = testId => {
      const allLiItems = testItemGroup.value.$slots.default
      const liItem = allLiItems.find(slot => slot.data.ref === testId).elm
      if (liItem) {
        // Remove all focused items before assigning a new one
        const focusedItems = testItemGroup.value.$el.querySelectorAll('.focus')
        if (focusedItems) {
          focusedItems.forEach(element => {
            element.classList.remove('focus')
          })
        }
        // Add highlight class to the selected item
        liItem.classList.add('focus')
      }
    }
    const scrollToTest = testId => {
      // Actual Scrollable container
      const container = testScrollContainer.value.$el
      // Container of all test case li
      const allLiItems = testItemGroup.value.$slots.default
      // Select the focused item
      const liItem = allLiItems.find(slot => slot.data.ref === testId).elm
      if (liItem) {
        const containerRect = container.getBoundingClientRect()
        const liItemRect = liItem.getBoundingClientRect()
        const scrollTop = liItemRect.top - containerRect.top + container.scrollTop

        // Scroll to item
        container.scrollTop = scrollTop

        focusOnTest(testId)
      }
    }

    // Inst pagination variables
    const totalRows = ref(0)
    const totalPages = ref(1)
    // Set overlay boolean
    const isLoading = ref(false)
    const fetchTests = () => {
      isLoading.value = true
      store.dispatch('tests/fetchTests', {
        model_id: store.state.model.id,
        q: router.currentRoute.query.q,
        filter: router.currentRoute.params.filter,
        priority: router.currentRoute.params.priority,
        sortBy: sortBy.value,
        currentPage: paginateData.value.currentPage,
        perPage: paginateData.value.perPage,
      })
        .then(response => {
          // tasks.value = response.data
          // Depending on total count of test cases, change pages and limits accordingly
          totalRows.value = response.data.total_count
          if (paginateData.value.perPage > 0) {
              totalPages.value = Math.max(1, Math.ceil(totalRows.value / paginateData.value.perPage));
          } else {
              // We want to show at least one empty page
              totalPages.value = 1;
          }

          // Remove overlay
          isLoading.value = false
          // // Open a test and highlight it in list if focused in route params
          if (focusedTestId.value) {
            const test = tests.value.find(element => element.id === focusedTestId.value)
            if (test) {
              handleTestClick(test)
            }
          }
        })
    }

    watch(routeParams, () => {
      paginateData.value.currentPage = 1
      fetchTests()
    })

    function setPageNumber() {
      if (focusedTestId.value) {
        store.dispatch('tests/getPageNumberByTestId', {
          id: focusedTestId.value,
          perPage: paginateData.value.perPage,
        })
          .then(() => {
            paginateData.value.currentPage = store.state.tests.pageNumber
            fetchTests()
          })
      } else {
        fetchTests()
      }
    }

    setPageNumber()


    const handleTestClick = taskData => {
      focusOnTest(taskData.id)
      if (!isTaskHandlerSidebarActive.value) {
        task.value = taskData
        isTaskHandlerSidebarActive.value = true
        const { href } = router.resolve({
          name: 'model_test_focus',
          params: { ...route.params, testId: task.value.id },
        })
        window.history.pushState({}, null, href)
      }
    }

    const hideSidebar = () => {
      if (isTaskHandlerSidebarActive.value) {
        isTaskHandlerSidebarActive.value = false
        task.value = {}
        const { href } = router.resolve({ name: 'model_tests' })
        window.history.pushState({}, null, href)
      }
    }

    // Pagination Methods
    const isEditingPageNumber = ref(false)
    const editablePageNumber = ref(1)

    const nextPage = () => {
      if (paginateData.value.currentPage < totalPages.value) {
        paginateData.value.currentPage++;
        isEditingPageNumber.value = false
        fetchTests()
      }
    }

    const prevPage = () => {
      if (paginateData.value.currentPage > 1) {
        paginateData.value.currentPage--;
        isEditingPageNumber.value = false
        fetchTests()
      }
    }

    function postChangePerPageLimit() {
      // Change current page value to avoid errors
      paginateData.value.currentPage = 1
      isEditingPageNumber.value = false
      fetchTests()
    }

    const refreshTests = () => {
      isEditingPageNumber.value = false
      paginateData.value.currentPage = 1

      // Remove all focused items
      const focusedItems = testItemGroup.value.$el.querySelectorAll('.focus')
      if (focusedItems) {
        focusedItems.forEach(element => {
          element.classList.remove('focus')
        })
      }

      // Remove the selected test from the route query
      router.replace({ name: route.name }).catch(() => {})

      fetchTests()
    }

    function editPageNumber() {
      isEditingPageNumber.value = true
      editablePageNumber.value = paginateData.value.currentPage
    }

    function finishEditingPageNumber() {
      // Enforce limits before changing page number
      // Unfortunately the input field (despite being set to type "number" will convert the var to string,
      // hence the conversion back
      if (editablePageNumber.value !== '' && editablePageNumber.value !== null) {
        let parsedPageNumber = parseInt(editablePageNumber.value)
        if (parsedPageNumber > totalPages.value) parsedPageNumber = totalPages.value
        else if (parsedPageNumber < 1) parsedPageNumber = 1

        isEditingPageNumber.value = false
        paginateData.value.currentPage = parsedPageNumber
        fetchTests()
      } else {
        console.error('Invalid page number entered')
      }
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      task,
      tasks,
      tests,
      totalRows,
      totalPages,
      removeTask,
      updateTask,
      clearTaskData,
      testPriorities,
      searchQuery,
      fetchTests,
      hideSidebar,
      perfectScrollbarSettings,
      updateRouteQuery,
      searchButton,
      resetSortAndNavigate,
      paginateData,
      pageOptions,

      // UI
      isTaskHandlerSidebarActive,
      isLoading,
      testScrollContainer,
      testItemGroup,
      scrollToTest,

      // Test items
      resolveLatestTestResult,
      resolveTestPriority,

      // Click Handler
      handleTestClick,
      prevPage,
      nextPage,
      postChangePerPageLimit,
      isEditingPageNumber,
      editablePageNumber,
      editPageNumber,
      finishEditingPageNumber,
      refreshTests,

      // Filters
      formatDate,
      avatarText,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
}

.spinner-container {
  width: calc(100% - 260px);
  height: calc(100% - 3.56rem);
  position: absolute;
  z-index: 9999;
}

.page-number-input {
  background-color: rgba(0, 0, 0, 0.15) !important;
  width: 3rem;
  font-size: 1rem;
}

.focus {
  background-color: #6495ed36;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
